import { apiFetch } from '@m/utils/http';
import { createEffect } from 'effector';

export const fetchForceCloseReasons = createEffect({
  handler: () => apiFetch('/specialist/visits/force-close-reasons'),
});

export const forceCloseVisit = createEffect({
  handler: async ({
    visitId,
    forceCloseReasonId,
  }: {
    visitId: number;
    forceCloseReasonId: number;
  }) =>
    apiFetch(`/specialist/visit/${visitId}/force-close`, {
      method: 'POST',
      body: {
        forceCloseReason: { id: forceCloseReasonId },
      },
    }),
});
