import { ReactComponent as Logo } from '@m/assets/svg/logo.svg';
import { brandColorCycle, brandColorCycleSpin } from '@m/styled/keyframes';
import styled from 'styled-components';

export const LoginProgressBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;

  @media (min-width: 700px) {
    justify-content: center;
  }
`;

export const LoginProgressContent = styled.div<{ darkMode?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 280px;
  height: 260px;
  position: relative;
  background-color: ${({ darkMode, theme }) => (darkMode ? 'rgb(57, 63, 69)' : theme.colors.white)};

  @media (min-width: 700px) {
    background-color: ${({ darkMode, theme }) =>
      darkMode ? 'rgb(57, 63, 69)' : theme.colors.white};
    border-radius: 7px;
    box-shadow:
      0 0 0 0.5px rgba(0, 0, 0, 0.05),
      0 1px 3px 0 rgba(0, 0, 0, 0.075),
      0 4px 12px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const LoginProgressHeader = styled.div<{ darkMode?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 52px;
  background-color: ${({ darkMode, theme }) => (darkMode ? 'rgb(56, 61, 67)' : theme.colors.blue1)};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-shadow: 0 1px 0
    ${({ darkMode, theme }) => (darkMode ? theme.colors.black : theme.colors.blue2)};
`;

export const SpinnerBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

export const LoadingSpinner = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  border-top: 3px solid currentColor;
  border-right: 4px solid currentColor;
  border-bottom: 4px solid transparent;
  border-left: 2px solid currentColor;
  border-radius: 50%;
  animation: ${brandColorCycleSpin} 5.5s linear 0ms infinite;
`;

export const LogoSpinnerBox = styled.div`
  animation: ${brandColorCycle} 5.5s linear 0ms infinite;
`;
export const LogoSpinner = styled(Logo)`
  display: block;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
  fill: currentColor;
`;
