import { lazy, Suspense, useEffect } from 'react';

import AppAlert from '@m/components/AppAlert';
import LoginRedirectPage from '@m/components/login/LoginRedirectPage';
import { useHeapIdentifyUser } from '@m/hooks/useHeapIdentifyUser';
import { useUnit } from 'effector-react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { Wrapper } from 'apps/specialist/components/Layout';
import LoginPromptContainer from 'apps/specialist/containers/LoginPromptContainer';
import { useIsAuthed } from 'apps/specialist/hooks/useIsAuthed';
import { getUser, loginMicrosoft, userStore } from 'apps/specialist/state/user';
import { fetchForceCloseReasons } from 'apps/specialist/state/visit';

const SiteChooserContainer = lazy(() => import('apps/specialist/containers/SiteChooserContainer'));
const SiteContainer = lazy(() => import('apps/specialist/containers/SiteContainer'));
const NavbarContainer = lazy(() => import('apps/specialist/containers/NavbarContainer'));

export default function Specialist() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { user } = useUnit(userStore);

  const isAuthed = useIsAuthed();

  // initial fetch of user data
  useEffect(() => {
    getUser();
  }, []);

  // fetch necessary data after authenticated
  useEffect(() => {
    if (isAuthed) {
      fetchForceCloseReasons();
    }
  }, [isAuthed]);

  useEffect(() => {
    if (isAuthed === false && pathname !== '/login' && pathname !== '/login-redirect') {
      history.replace('/login');
    } else if (isAuthed && (pathname === '/login' || pathname === '/login-redirect')) {
      history.replace('/');
    }
  }, [isAuthed, pathname, history]);

  useHeapIdentifyUser({
    authenticated: isAuthed,
    user,
  });

  return (
    <>
      <AppAlert />
      <Wrapper>
        <Suspense fallback={null}>{isAuthed && <NavbarContainer />}</Suspense>
        <Switch>
          <Route exact path="/">
            <Suspense fallback={null}>{isAuthed && <SiteChooserContainer />}</Suspense>
          </Route>
          {isAuthed !== null && (
            <Route exact path="/login">
              <LoginPromptContainer />
            </Route>
          )}
          <Route exact path="/login-redirect">
            <div css="margin-top: 48px;">
              <LoginRedirectPage doLogin={loginMicrosoft} darkMode />
            </div>
          </Route>
          <Route exact path="/site/:siteId">
            <Suspense fallback={null}>{isAuthed && <SiteContainer />}</Suspense>
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
}
