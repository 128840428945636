import { KeycloakService } from '@m/keycloak-service/KeycloakService';
import initApp from '@m/utils/initApp';
import { createRoot } from 'react-dom/client';

import SpecialistApp from 'apps/specialist';

import * as serviceWorker from './serviceWorker';

import 'antd/es/style/css';

initApp('specialist');

const root = createRoot(document.getElementById('root')!);

KeycloakService.initKeycloak('check-sso', () => {
  root.render(<SpecialistApp />);
});

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
