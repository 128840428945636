/* eslint-disable no-nested-ternary */
import { Button } from '@m/components/uikit';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)<{
  animations?: boolean;
  reverseColors?: boolean;
  textCenter?: boolean;
  removeDisabledFilter?: boolean;
  greenVariant?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  width: 100%;
  height: 56px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: start;
  letter-spacing: 0.02em;
  flex-shrink: 0;

  /* this causes buttons to highlight blue on small chrome browsers */
  -webkit-tap-highlight-color: transparent;

  padding: 0 15px;
  background-color: ${({ theme: { colors }, greenVariant }) =>
    greenVariant ? 'rgb(0, 165, 86)' : colors.blue6};
  border: 1px solid
    ${({ theme: { colors }, greenVariant }) => (greenVariant ? 'rgb(0, 165, 86)' : colors.blue6)} !important;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-rendering: geometricPrecision;

  &:active {
    background-color: ${({ reverseColors, greenVariant }) =>
      reverseColors
        ? 'rgba(95,89,255,0.7)'
        : greenVariant
          ? 'rgba(0, 165, 86, 0.6)'
          : 'rgba(95,89,255,0.6)'};
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid
      ${({ reverseColors, greenVariant }) =>
        reverseColors
          ? 'rgba(95,89,255,0.7)'
          : greenVariant
            ? 'rgba(0, 165, 86, 0.6)'
            : 'rgba(95,89,255,0.6)'} !important;
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    ${({ removeDisabledFilter }) => !removeDisabledFilter && 'opacity: 0.3;'}
  }

  ${({ animations }) => animations && 'transition: 800ms background-color;'}
  ${({ textCenter }) => textCenter && 'text-align: center;'}
  ${({ reverseColors, theme }) =>
    reverseColors &&
    css`
      color: ${theme.colors.blue6};
      background-color: #fff;
      border: 1px solid rgba(95, 89, 255, 0.5) !important;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 20px;
  flex-grow: 1;
`;

export const LoadingContainer = styled.div`
  justify-content: center;
  display: flex;
  flex: 1;
`;
