import { MsalProvider } from '@azure/msal-react';
import { CONNECT_TO_EXTERNAL_API } from '@m/constants/FeatureFlags';
import { setDefaultAuthMethod } from '@m/utils/http';
import { msalInstance } from '@m/utils/msalConfig';

import Providers from 'providers';

import Specialist from './Specialist';
import { AntdOverrides, GlobalStyles } from './SpecialistApp.styled';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function SpecialistApp() {
  return (
    <Providers>
      <MsalProvider instance={msalInstance}>
        <Specialist />
      </MsalProvider>
      <AntdOverrides />
      <GlobalStyles />
    </Providers>
  );
}
